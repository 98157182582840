import { useRequest } from '@/composables/useRequest';
import { X_DEVICE_TYPE } from '@/constants/common.const';
import { ApiMethod, HeaderTypes } from '@/enums/api.enum';
import { fetchNoticeListMapper } from '@/mappers/notice.mapper';
import type { Pagination } from '@/types/common/pagination.type';
import type { NoticeModel } from '@/types/notice/notice-model.type';
import type {
  NoticeDetailRequest,
  NoticeMoreRequest,
  NoticeRequest
} from '@/types/notice/notice-request.type';
import type {
  LatestNoticeResponse,
  NoticeDetailResponse,
  NoticeResponse,
  NoticesResponse
} from '@/types/notice/notice-response.type';
import { camelToSnake, generateHeader } from '@/utils/api.util';

// https://wiki.smilegate.net/pages/viewpage.action?pageId=454909369
export const fetchNoticeListApi = async (
  channelSeq: number,
  params: NoticeRequest
): Promise<Pagination<NoticeModel[]> | undefined> => {
  const { data } = await useRequest<Pagination<NoticeModel[]>, NoticesResponse>(
    `cwms/v3.0/channel/${channelSeq}/notice/list`,
    {
      headers: {
        ...generateHeader(
          HeaderTypes.XLang,
          HeaderTypes.XNation,
          HeaderTypes.CallerId,
          HeaderTypes.CallerDetail
        ),
        'X-Device-Type': X_DEVICE_TYPE
      },
      method: ApiMethod.Get,
      params: camelToSnake(params)
    },
    fetchNoticeListMapper
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=407297390
export const fetchNoticeDetailApi = async (
  params: NoticeDetailRequest
): Promise<NoticeDetailResponse | undefined> => {
  const { data } = await useRequest<NoticeDetailResponse>('cwms/v3.0/article', {
    headers: {
      ...generateHeader(HeaderTypes.XLang, HeaderTypes.XNation, HeaderTypes.CallerId)
    },
    params: camelToSnake(params)
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=458937514
export const fetchNoticeMoreApi = async (
  articleId: string,
  params: NoticeMoreRequest
): Promise<NoticeResponse[]> => {
  const { data } = await useRequest<{ list: NoticeResponse[] }>(
    `cwms/v3.0/notice/${articleId}/more`,
    {
      headers: {
        ...generateHeader(
          HeaderTypes.XLang,
          HeaderTypes.XNation,
          HeaderTypes.CallerId,
          HeaderTypes.CallerDetail
        ),
        'X-Device-Type': X_DEVICE_TYPE
      },
      params: camelToSnake(params)
    }
  );

  return data?.list ?? [];
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=462202518
export const fetchLatestNoticeApi = async (
  channelSeq: number,
  params: Omit<NoticeRequest, 'page' | 'size'>
): Promise<LatestNoticeResponse | undefined> => {
  const { data } = await useRequest<LatestNoticeResponse>(
    `cwms/v3.0/channel/${channelSeq}/notice/latest`,
    {
      headers: {
        ...generateHeader(
          HeaderTypes.XLang,
          HeaderTypes.XNation,
          HeaderTypes.CallerId,
          HeaderTypes.CallerDetail
        ),
        'X-Device-Type': X_DEVICE_TYPE
      },
      showCommonError: false,
      params: camelToSnake(params)
    }
  );

  return data;
};
