import type { ResponseNormalization } from 'seed-core';

import { Confirmation } from '@/enums/common.enum';
import type { Pagination } from '@/types/common/pagination.type';
import type { NoticeModel } from '@/types/notice/notice-model.type';
import type { NoticeResponse, NoticesResponse } from '@/types/notice/notice-response.type';

export const fetchNoticeListMapper = (
  res: ResponseNormalization<NoticesResponse>
): ResponseNormalization<Pagination<NoticeModel[]>> => {
  const contents: NoticeModel[] =
    res.data?.list?.map((item: NoticeResponse) => ({
      noticeId: item.articleId,
      label: item.headlineInfo.headlineName ?? '',
      title: item.title,
      attachedFile: item.attachSummaryInfo.attachFileYn === Confirmation.YES,
      createDt: item.createDatetime,
      isFixed: item.fixedYn === Confirmation.YES
    })) || [];

  const totalPages = (res.data?.total ?? 0) / (res.data?.size ?? 1);

  const data: Pagination<NoticeModel[]> = {
    totalElements: res.data?.total ?? 0,
    page: res.data?.page ?? 0,
    size: res.data?.size ?? 0,
    totalPages: Math.ceil(totalPages),
    contents,
    last: totalPages === res.data?.page,
    first: res.data?.page === 1
  };

  return {
    ...res,
    data
  };
};
